body {
  background-color: black;
  background-image: url("/hintergrund.jpg");
  background-position: center center;
  background-repeat:no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.App {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: 600;
  color: white;
}

.button-list {
  max-width: 50rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.byteMonkeys {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.5rem;
  margin: 1.25rem;
}