.icon {
    width: 8rem;
    border-radius: 4rem;
  }
  
  .timer {
    position: absolute;
    top: 2.5rem;
    text-align: center;
    width: 8rem;
    color: white;
    background-color: rgba(0,0,0,0.2);
  }
  
  .wrapper {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
    outline: inherit;
    
    margin: 2rem;
    width: 8rem;
    height: 8rem;
    position: relative;
  }